import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';
import { IdFilterFields } from '../../../../../types';

import { useLifestyleAuthorsSelected } from '../../../hooks/useLifestyleAuthorsSelected';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

interface LifestylesAuthorsFilterBadgeItem {
  id: string;
  label: string;
}

function LifestylesAuthorsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const filterValue = filters?.[name]?.in || [];

  const { lifestyleAuthorsSelected } = useLifestyleAuthorsSelected({
    selectedIds: filterValue,
    cacheKey: LifestyleCache.authorsSelectedFilterCacheKey(),
    initialFilters: {
      scope: [FetchCategoriesScopes.SCENE_PERFORMERS],
      id: { in: [] }
    },
    keepPreviousData: true
  });

  const lifestyleAuthorsSelectedData = useMemo<
    LifestylesAuthorsFilterBadgeItem[]
  >(
    () =>
      lifestyleAuthorsSelected.map((category) => ({
        id: category.id as string,
        label: category.localizedName
      })),
    [lifestyleAuthorsSelected]
  );

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.authors.plural}
      items={lifestyleAuthorsSelectedData}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default LifestylesAuthorsFilterBadge;
