import React, { Fragment, memo } from 'react';

import {
  ChangeLifestylesFiltersFunc,
  FetchLifestylesFilters,
  LifestylesFiltersNavBasePath,
  LifestylesCustomExitNavPath,
  LifestylesI18nCustomBaseTitle,
  FetchLifestylesTotalCount
} from '../../lifestylesTypes';
import { IconsEnum } from '../../../../assets/icons/types';

import useLifestylesIndexPageBreadcrumbs from './useLifestylesIndexPageBreadcrumbs';

import { LifestylesIndexPageBreadcrumbsItem } from './components/LifestylesIndexPageBreadcrumbsItem';

import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { getChangeLifestylesFiltersCategoriesData } from '../../utils/getChangeLifestylesFiltersCategoriesData';

import { LifestylePath } from '../../LifestylePath';
import { StockPath } from '../../../stock/StockPath';
import { LifestylesPermissions } from '../../lifestylesConstants';

import { lifestylesKeys } from '../../../../locales/keys';

interface LifestylesIndexPageBreadcrumbsProps {
  filtersNavBasePath?: LifestylesFiltersNavBasePath;
  customExitNavPath?: LifestylesCustomExitNavPath;
  i18nCustomBaseTitle?: LifestylesI18nCustomBaseTitle;
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
  lifestylesTotalCount: FetchLifestylesTotalCount;
  withoutStockLink?: boolean;
}

function LifestylesIndexPageBreadcrumbs({
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  lifestylesFilters,
  changeLifestylesFilters,
  lifestylesTotalCount,
  withoutStockLink
}: LifestylesIndexPageBreadcrumbsProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    lifestyleCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useLifestylesIndexPageBreadcrumbs({
    lifestylesFilters,
    changeLifestylesFilters
  });

  return (
    <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
      {!withoutStockLink && (
        <Fragment>
          <NextPureLinkHelper
            href={customExitNavPath || StockPath.index()}
            className="hover:underline"
            icon={IconsEnum.CUBE_OUTLINE}
            iconClassName="h-6 w-6"
          />
          <span>/</span>
        </Fragment>
      )}

      <LifestylesIndexPageBreadcrumbsItem
        href={filtersNavBasePath}
        active={!currentChildCategory && !currentParentCategory}
        i18nText={i18nCustomBaseTitle || lifestylesKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        onChangeFilters={handleResetCategoriesFilters}
      />
      {currentParentCategory ? (
        <>
          {currentParentCategory.parent && (
            <>
              <span>/</span>
              <LifestylesIndexPageBreadcrumbsItem
                href={
                  filtersNavBasePath
                    ? LifestylePath.indexFiltersCustomBaseQuery(
                        filtersNavBasePath,
                        getChangeLifestylesFiltersCategoriesData({
                          lifestyleCategory: currentParentCategory.parent,
                          categories: lifestyleCategories
                        }).changeFilters
                      )
                    : '#'
                }
                active={!currentChildCategory}
                text={currentParentCategory.parent.localizedName}
                filtersNavBasePath={filtersNavBasePath}
                onChangeFilters={handleParentCategoryChangeFilters}
              />
            </>
          )}

          <span>/</span>
          <LifestylesIndexPageBreadcrumbsItem
            href={
              filtersNavBasePath
                ? LifestylePath.indexFiltersCustomBaseQuery(
                    filtersNavBasePath,
                    getChangeLifestylesFiltersCategoriesData({
                      lifestyleCategory: currentParentCategory,
                      categories: lifestyleCategories
                    }).changeFilters
                  )
                : '#'
            }
            active={!currentChildCategory}
            text={currentParentCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            onChangeFilters={handleParentCategoryChangeFilters}
          />
        </>
      ) : null}

      {currentChildCategory ? (
        <>
          <span>/</span>
          <LifestylesIndexPageBreadcrumbsItem
            active={true}
            text={currentChildCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
          />
        </>
      ) : null}
      {lifestylesTotalCount ? (
        <CheckPermissions
          action={LifestylesPermissions.READ_LIFESTYLES_TOTAL_COUNT}
        >
          <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
            {lifestylesTotalCount}
          </span>
        </CheckPermissions>
      ) : null}
    </div>
  );
}

export default memo<LifestylesIndexPageBreadcrumbsProps>(
  LifestylesIndexPageBreadcrumbs
);
