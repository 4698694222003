import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import { useLifestyleStyles } from '../../../hooks/useLifestyleStyles';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

interface LifestylesStylesFilterBadgeItem {
  id: string;
  label: string;
}

function LifestylesStylesFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { lifestyleStyles } = useLifestyleStyles({
    cacheKey: LifestyleCache.stylesFilterCacheKey(),
    initialFilters: { scope: [FetchCategoriesScopes.SCENE_STYLES] }
  });

  const LifestylesStylesFilterBadgeItems = useMemo<
    LifestylesStylesFilterBadgeItem[]
  >(() => {
    return lifestyleStyles.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleStyles]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.styles.singular}
      items={LifestylesStylesFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default LifestylesStylesFilterBadge;
