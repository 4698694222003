import React, { useMemo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FetchLifestylesFilters,
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { useLifestyleTones } from '../../../hooks/useLifestyleTones';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getLifestyleClientIdsCacheKeyPart } from '../../../utils/getLifestyleClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import { LifestylesTonesFilterDataItem } from '../LifestylesTonesFilter/LifestylesTonesFilter.types';

interface LifestylesTonesFilterProps {
  clientIds?: LifestyleClientID[];
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'toneId' as const;

const defaultInitialFilters = { scope: [FetchCategoriesScopes.SCENE_TONES] };

function LifestylesTonesFilter({
  clientIds,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesTonesFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.tonesFilterCacheKey()
    : LifestyleCache.tonesLibraryFilterCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );

  const initialFilters = isEmpty(clientIds)
    ? defaultInitialFilters
    : { ...defaultInitialFilters, lifestyleClientIds: clientIds };

  const {
    lifestyleTones,
    lifestyleTonesErrorMessage,
    lifestyleTonesFetched,
    lifestyleTonesIsPlaceholderData
  } = useLifestyleTones({
    cacheKey,
    initialFilters
  });

  const lifestylesTonesFilterData = useMemo<
    LifestylesTonesFilterDataItem[]
  >(() => {
    return lifestyleTones.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleTones]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeLifestylesFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeLifestylesFilters]
  );

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.tones.singular} />
        </h6>
      </div>
      <AlertMessage message={lifestyleTonesErrorMessage} />
      <LoadingSkeleton
        loaded={lifestyleTonesIsPlaceholderData || lifestyleTonesFetched}
      >
        <CheckboxGroupFilter
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={lifestylesTonesFilterData}
          value={lifestylesFilters?.[name]?.in as string[]}
          onChange={handleChange}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesTonesFilter;
