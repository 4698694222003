import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_LIFESTYLE_MATERIALS_FILTERS,
  INITIAL_LIFESTYLE_MATERIALS_LIMIT,
  INITIAL_LIFESTYLE_MATERIALS_SORT
} from '../../lifestylesConstants';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesFilters,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  FetchLifestyleMaterialsQueryResponse,
  FETCH_LIFESTYLE_MATERIALS_QUERY
} from '../../queries/fetchLifestyleMaterials.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleMaterialsDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters?: FetchLifestyleCategoriesFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
}

function useLifestyleMaterials({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_MATERIALS_FILTERS,
  initialSort = INITIAL_LIFESTYLE_MATERIALS_SORT,
  initialLimit = INITIAL_LIFESTYLE_MATERIALS_LIMIT
}: LifestyleMaterialsDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchLifestyleMaterialsQueryResponse>({
      cacheKey,
      query: FETCH_LIFESTYLE_MATERIALS_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    lifestyleMaterials: items,
    lifestyleMaterialsErrorMessage: itemsError,
    lifestyleMaterialsFetched: isFetched,
    lifestyleMaterialsIsPlaceholderData: isPlaceholderData
  };
}

export default useLifestyleMaterials;
