import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_LIFESTYLE_TONES_FILTERS,
  INITIAL_LIFESTYLE_TONES_LIMIT,
  INITIAL_LIFESTYLE_TONES_SORT
} from '../../lifestylesConstants';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesFilters,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  FetchLifestyleTonesQueryResponse,
  FETCH_LIFESTYLE_TONES_QUERY
} from '../../queries/fetchLifestyleTones.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleTonesDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters?: FetchLifestyleCategoriesFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
}

function useLifestyleTones({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_TONES_FILTERS,
  initialSort = INITIAL_LIFESTYLE_TONES_SORT,
  initialLimit = INITIAL_LIFESTYLE_TONES_LIMIT
}: LifestyleTonesDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchLifestyleTonesQueryResponse>({
      cacheKey,
      query: FETCH_LIFESTYLE_TONES_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    lifestyleTones: items,
    lifestyleTonesErrorMessage: itemsError,
    lifestyleTonesFetched: isFetched,
    lifestyleTonesIsPlaceholderData: isPlaceholderData
  };
}

export default useLifestyleTones;
