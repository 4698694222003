import { useInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_LIFESTYLE_AUTHORS_FILTERS,
  INITIAL_LIFESTYLE_AUTHORS_LIMIT,
  INITIAL_LIFESTYLE_AUTHORS_SORT
} from '../../lifestylesConstants';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesFilters,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  FetchLifestyleAuthorsQueryResponse,
  FETCH_LIFESTYLE_AUTHORS_QUERY
} from '../../queries/fetchLifestyleAuthors.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleColorsDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters?: FetchLifestyleCategoriesFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
}

function useLifestyleAuthors({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_AUTHORS_FILTERS,
  initialSort = INITIAL_LIFESTYLE_AUTHORS_SORT,
  initialLimit = INITIAL_LIFESTYLE_AUTHORS_LIMIT
}: LifestyleColorsDefaultOptions) {
  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    isFetchingNextPage,
    currentFilters,
    hasNextPage,
    loadMoreItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<FetchLifestyleAuthorsQueryResponse>({
    cacheKey,
    query: FETCH_LIFESTYLE_AUTHORS_QUERY,
    initialFilters,
    initialSort,
    initialLimit,
    scope,
    options
  });

  return {
    lifestyleAuthors: items,
    lifestyleAuthorsErrorMessage: itemsError,
    lifestyleAuthorsFetched: isFetched,
    lifestyleAuthorsIsPlaceholderData: isPlaceholderData,
    lifestyleAuthorsFetchingNextPage: isFetchingNextPage,
    hasNextLifestyleAuthorsPage: hasNextPage,
    loadMoreLifestyleAuthors: loadMoreItems,
    lifestyleAuthorsFilterSearchValue: currentFilters?.name?.ilike,
    changeLifestyleAuthorsFilters: changeItemsFilters
  };
}

export default useLifestyleAuthors;
