import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_LIFESTYLE_TYPES_FILTERS,
  INITIAL_LIFESTYLE_TYPES_LIMIT,
  INITIAL_LIFESTYLE_TYPES_SORT
} from '../../lifestylesConstants';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesFilters,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  FetchLifestyleTypesQueryResponse,
  FETCH_LIFESTYLE_TYPES_QUERY
} from '../../queries/fetchLifestyleTypes.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleTypesDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters?: FetchLifestyleCategoriesFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useLifestyleTypes({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_TYPES_FILTERS,
  initialSort = INITIAL_LIFESTYLE_TYPES_SORT,
  initialLimit = INITIAL_LIFESTYLE_TYPES_LIMIT
}: LifestyleTypesDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchLifestyleTypesQueryResponse>({
      cacheKey,
      query: FETCH_LIFESTYLE_TYPES_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    lifestyleTypes: items,
    lifestyleTypesErrorMessage: itemsError,
    lifestyleTypesFetched: isFetched,
    lifestyleTypesIsPlaceholderData: isPlaceholderData
  };
}

export default useLifestyleTypes;
