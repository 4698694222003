import React, { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import {
  FetchLifestylesFilters,
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { useLifestyleMaterials } from '../../../hooks/useLifestyleMaterials';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getLifestyleClientIdsCacheKeyPart } from '../../../utils/getLifestyleClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import { LifestylesMaterialsFilterDataItem } from './LifestylesMaterialsFilter.types';

interface LifestylesMaterialsFilterProps {
  clientIds?: LifestyleClientID[];
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'materialIds' as const;

const defaultInitialFilters = {
  scope: [FetchCategoriesScopes.SCENE_MATERIALS]
};

function LifestylesMaterialsFilter({
  clientIds,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesMaterialsFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.materialsFilterCacheKey()
    : LifestyleCache.materialsLibraryFilterCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );

  const initialFilters = isEmpty(clientIds)
    ? defaultInitialFilters
    : { ...defaultInitialFilters, lifestyleClientIds: clientIds };

  const {
    lifestyleMaterials,
    lifestyleMaterialsErrorMessage,
    lifestyleMaterialsFetched,
    lifestyleMaterialsIsPlaceholderData
  } = useLifestyleMaterials({
    cacheKey,
    initialFilters
  });

  const LifestylesMaterialsFilterItems = useMemo<
    LifestylesMaterialsFilterDataItem[]
  >(
    () =>
      sortBy(lifestyleMaterials, 'localizedName').map((category) => ({
        id: category.id as string,
        label: category.localizedName
      })),
    [lifestyleMaterials]
  );

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.materials.singular} />
        </h6>
      </div>
      <AlertMessage message={lifestyleMaterialsErrorMessage} />
      <LoadingSkeleton
        loaded={
          lifestyleMaterialsIsPlaceholderData || lifestyleMaterialsFetched
        }
      >
        <CheckboxGroupFilter
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={LifestylesMaterialsFilterItems}
          value={lifestylesFilters?.[name] as string[]}
          onChange={changeLifestylesFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesMaterialsFilter;
