import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ClientError } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  FetchLifestyleAuthorsSelectedQueryResponse,
  FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY
} from '../../queries/fetchLifestyleAuthorsSelected.query';

import { fetchItems } from '../../../common/hooks/base/reactQuery/baseActions/fetchItems';

import { CategoryID } from '../../../categories/categoriesTypes';

import { parseRequestError } from '../../../../utils/parseRequestError';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesPage,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  INITIAL_LIFESTYLE_AUTHORS_SELECTED_FILTERS,
  INITIAL_LIFESTYLE_AUTHORS_SELECTED_LIMIT,
  INITIAL_LIFESTYLE_AUTHORS_SELECTED_PAGE,
  INITIAL_LIFESTYLE_AUTHORS_SELECTED_SORT
} from '../../lifestylesConstants';

type LifestylesAuthorsFilterSelectedErrorType = Error | ClientError;

interface LifestylesAuthorsFilterSelectedResponse {
  categories: {
    nodes: FetchLifestyleAuthorsSelectedQueryResponse[];
  };
}

interface LifestyleAuthorsSelectedFilters {
  scope?: string[];
  id?: { in: CategoryID[] };
}

interface LifestyleAuthorsSelectedDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters: LifestyleAuthorsSelectedFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
  initialPage?: FetchLifestyleCategoriesPage;
  keepPreviousData?: boolean;
  selectedIds?: CategoryID[];
}

const staleTime = 1000 * 60 * 60;

function useLifestyleAuthorsSelected({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_AUTHORS_SELECTED_FILTERS,
  initialSort = INITIAL_LIFESTYLE_AUTHORS_SELECTED_SORT,
  initialLimit = INITIAL_LIFESTYLE_AUTHORS_SELECTED_LIMIT,
  initialPage = INITIAL_LIFESTYLE_AUTHORS_SELECTED_PAGE,
  keepPreviousData,
  selectedIds = []
}: LifestyleAuthorsSelectedDefaultOptions) {
  const [currentFilters, setCurrentFilters] =
    useState<LifestyleAuthorsSelectedFilters>(initialFilters);

  const currentParams = {
    filters: currentFilters,
    sort: initialSort,
    page: initialPage,
    limit: initialLimit
  };

  const { data, error } = useQuery<
    LifestylesAuthorsFilterSelectedResponse,
    LifestylesAuthorsFilterSelectedErrorType
  >(
    [cacheKey, currentParams],
    () =>
      fetchItems({
        query: FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
        ...currentParams
      }),
    {
      enabled: !isEmpty(currentFilters?.id?.in),
      staleTime,
      keepPreviousData
    }
  );

  const queryResponseValue = data?.categories;

  const items =
    queryResponseValue?.nodes ||
    <FetchLifestyleAuthorsSelectedQueryResponse[]>[];

  useEffect(() => {
    if (isEmpty(selectedIds) && isEmpty(currentFilters?.id?.in)) {
      return;
    }

    if (!isEqual(selectedIds, currentFilters.id?.in)) {
      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        id: { in: selectedIds }
      }));
    }
  }, [selectedIds, currentFilters, setCurrentFilters]);

  return {
    lifestyleAuthorsSelected: items,
    lifestyleAuthorsSelectedErrorMessage: parseRequestError(error)
  };
}

export default useLifestyleAuthorsSelected;
