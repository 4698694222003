import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_LIFESTYLE_COLORS_FILTERS,
  INITIAL_LIFESTYLE_COLORS_LIMIT,
  INITIAL_LIFESTYLE_COLORS_SORT
} from '../../lifestylesConstants';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesFilters,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  FETCH_LIFESTYLE_COLORS_QUERY,
  FetchLifestyleColorsQueryResponse
} from '../../queries/fetchLifestyleColors.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleColorsDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters?: FetchLifestyleCategoriesFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
}

function useLifestyleColors({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_COLORS_FILTERS,
  initialSort = INITIAL_LIFESTYLE_COLORS_SORT,
  initialLimit = INITIAL_LIFESTYLE_COLORS_LIMIT
}: LifestyleColorsDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchLifestyleColorsQueryResponse>({
      cacheKey,
      query: FETCH_LIFESTYLE_COLORS_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    lifestyleColors: items,
    lifestyleColorsErrorMessage: itemsError,
    lifestyleColorsFetched: isFetched,
    lifestyleColorsIsPlaceholderData: isPlaceholderData
  };
}

export default useLifestyleColors;
