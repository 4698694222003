import { useCallback, useMemo } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { useLifestyleAuthors } from '../../../../../hooks/useLifestyleAuthors';
import { useLifestyleAuthorsSelected } from '../../../../../hooks/useLifestyleAuthorsSelected';

import { LifestyleCache } from '../../../../../LifestyleCache';

import {
  CategoryID,
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../../../../categories/categoriesTypes';

import {
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../../../lifestylesTypes';
import { LifestylesAuthorsFilterDataItem } from '../../LifestylesAuthorsFilter.types';

interface useLifestylesAuthorsFilterProps {
  clientIds?: LifestyleClientID[];
  selectedIds: CategoryID[];
  name: string;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const defaultInitialFilters = {
  scope: [FetchCategoriesScopes.SCENE_PERFORMERS]
};

function useLifestylesAuthorsFilter({
  clientIds,
  selectedIds = [],
  name,
  changeLifestylesFilters
}: useLifestylesAuthorsFilterProps) {
  // const cacheKey = clientId
  //   ? LifestyleCache.authorsLibraryFilterCacheKey(clientId)
  //   : LifestyleCache.authorsFilterCacheKey();
  // const initialFilters = clientId
  //   ? { ...defaultInitialFilters, lifestyleClientIds: [clientId] }
  //   : defaultInitialFilters;

  const {
    lifestyleAuthors,
    lifestyleAuthorsErrorMessage,
    lifestyleAuthorsFetched,
    lifestyleAuthorsIsPlaceholderData,
    lifestyleAuthorsFetchingNextPage,
    hasNextLifestyleAuthorsPage,
    lifestyleAuthorsFilterSearchValue,
    loadMoreLifestyleAuthors,
    changeLifestyleAuthorsFilters
  } = useLifestyleAuthors({
    cacheKey: LifestyleCache.authorsFilterCacheKey(),
    initialFilters: defaultInitialFilters,
    initialSort: [FetchCategoriesSortTypes.LOCALIZED_NAME_ASC]
  });

  const { lifestyleAuthorsSelected, lifestyleAuthorsSelectedErrorMessage } =
    useLifestyleAuthorsSelected({
      selectedIds,
      cacheKey: LifestyleCache.authorsSelectedFilterCacheKey(),
      initialFilters: {
        scope: [FetchCategoriesScopes.SCENE_PERFORMERS],
        id: { in: [] }
      },
      keepPreviousData: true
    });

  const handleLifestyleAuthorsFilterSearch = useCallback(
    (input: string) => {
      changeLifestyleAuthorsFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeLifestyleAuthorsFilters]
  );

  const lifestyleAuthorsFilterSelectedData = useMemo(() => {
    const selectedFetchedData = lifestyleAuthorsSelected.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));

    const selectedData = compact(
      selectedIds.map((id) => find(selectedFetchedData, ['id', id]))
    );
    return selectedData;
  }, [lifestyleAuthorsSelected, selectedIds]);

  const lifestylesAuthorsFilterData = useMemo<
    LifestylesAuthorsFilterDataItem[]
  >(() => {
    return sortBy(
      lifestyleAuthors.map((category) => ({
        id: category.id as string,
        label: category.localizedName
      })),
      'label'
    );
  }, [lifestyleAuthors]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeLifestylesFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeLifestylesFilters, name]
  );

  return {
    lifestyleAuthorsFilterData: lifestyleAuthors,
    lifestyleAuthorsFilterSelectedData,
    lifestyleAuthorsFilter: lifestyleAuthors,
    lifestylesAuthorsFilterData,
    lifestyleAuthorsFilterErrorMessage: lifestyleAuthorsErrorMessage,
    lifestyleAuthorsFilterSelectedErrorMessage:
      lifestyleAuthorsSelectedErrorMessage,
    lifestyleAuthorsFilterFetched: lifestyleAuthorsFetched,
    lifestyleAuthorsFilterIsPlaceholderData: lifestyleAuthorsIsPlaceholderData,
    lifestyleAuthorsFilterFetchingNextPage: lifestyleAuthorsFetchingNextPage,
    hasNextLifestyleAuthorsFilterPage: hasNextLifestyleAuthorsPage,
    lifestyleAuthorsFilterSearchValue,
    handleLifestyleAuthorsFilterSearch,
    loadMoreLifestyleAuthorsFilter: loadMoreLifestyleAuthors,
    handleChangeLifestyleAuthorsFilter: handleChange
  };
}

export default useLifestylesAuthorsFilter;
