import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_LIFESTYLE_GAMMAS_FILTERS,
  INITIAL_LIFESTYLE_GAMMAS_LIMIT,
  INITIAL_LIFESTYLE_GAMMAS_SORT
} from '../../lifestylesConstants';

import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestyleCategoriesFilters,
  FetchLifestyleCategoriesLimit,
  FetchLifestyleCategoriesSort
} from '../../lifestylesTypes';

import {
  FetchLifestyleGammasQueryResponse,
  FETCH_LIFESTYLE_GAMMAS_QUERY
} from '../../queries/fetchLifestyleGammas.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleGammasDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  initialFilters?: FetchLifestyleCategoriesFilters;
  initialSort?: FetchLifestyleCategoriesSort;
  initialLimit?: FetchLifestyleCategoriesLimit;
}

function useLifestyleGammas({
  cacheKey,
  initialFilters = INITIAL_LIFESTYLE_GAMMAS_FILTERS,
  initialSort = INITIAL_LIFESTYLE_GAMMAS_SORT,
  initialLimit = INITIAL_LIFESTYLE_GAMMAS_LIMIT
}: LifestyleGammasDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchLifestyleGammasQueryResponse>({
      cacheKey,
      query: FETCH_LIFESTYLE_GAMMAS_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    lifestyleGammas: items,
    lifestyleGammasErrorMessage: itemsError,
    lifestyleGammasFetched: isFetched,
    lifestyleGammasIsPlaceholderData: isPlaceholderData
  };
}

export default useLifestyleGammas;
